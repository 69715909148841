var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-skeleton',{staticClass:"mb-4",attrs:{"header":_vm.$t('erikoistumisen-edistyminen'),"loading":_vm.initializing}},[(!_vm.initializing && _vm.edistyminen)?_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"xl":"8"}},[_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: 'arvioinnit'
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('arvioinnit'))+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column mb-2 col-min-width"},[(_vm.edistyminen.arviointienKeskiarvo)?_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.keskiarvoFormatted(_vm.edistyminen.arviointienKeskiarvo))+" / 5 ")]):_c('span',{staticClass:"text-size-lg"},[_vm._v("- / 5")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arviointien-ka'))+" "),_c('elsa-popover',{attrs:{"title":_vm.arviointiAsteikonNimi}},[_c('elsa-arviointiasteikon-taso-tooltip-content',{attrs:{"selite":_vm.arviointiAsteikonSelite,"arviointiasteikon-tasot":_vm.edistyminen.arviointiasteikko.tasot}})],1)],1)]),_c('div',{staticClass:"d-flex flex-column mb-2"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.edistyminen.arvioitavatKokonaisuudetVahintaanYksiArvioLkm)+" / "+_vm._s(_vm.edistyminen.arvioitavienKokonaisuuksienLkm)+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arv-kokonaisuutta-sis-vah-yhden-arvion'))+" ")])])])],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: 'suoritemerkinnat'
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('suoritemerkinnat'))+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column mb-2 col-min-width"},[(_vm.edistyminen.vaaditutSuoritemerkinnatLkm > 0)?_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.edistyminen.suoritemerkinnatLkm)+" / "+_vm._s(_vm.edistyminen.vaaditutSuoritemerkinnatLkm)+" "+_vm._s(_vm.$t('kpl'))+" ")]):_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.edistyminen.suoritemerkinnatLkm)+" "+_vm._s(_vm.$t('kpl'))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('suoritettu'))+" ")])]),(_vm.edistyminen.osaalueetVaadittuLkm > 0)?_c('div',{staticClass:"d-flex flex-column mb-2"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.edistyminen.osaalueetSuoritettuLkm)+" / "+_vm._s(_vm.edistyminen.osaalueetVaadittuLkm)+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('valmista-osa-aluetta'))+" ")])]):_vm._e()])],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: 'tyoskentelyjaksot'
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('tyoskentelyjaksot'))+" ")])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column mb-2 w-50"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.$duration( _vm.edistyminen.tyoskentelyjaksoTilastot.arvioErikoistumiseenHyvaksyttavista ))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arvio-erikoistumiseen-hyvaksyttavista'))+" ")])]),_c('div',{staticClass:"d-flex flex-column mb-2 pl-4 w-50"},[_c('span',{staticClass:"text-size-lg"},[_vm._v(" "+_vm._s(_vm.$duration(_vm.edistyminen.tyoskentelyjaksoTilastot.arvioPuuttuvastaKoulutuksesta))+" ")]),_c('span',{staticClass:"text-size-sm"},[_vm._v(" "+_vm._s(_vm.$t('arvio-puuttuvasta-koulutuksesta'))+" ")])])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"10"}},[_c('tyoskentelyjaksot-bar-chart',{attrs:{"tilastot":_vm.edistyminen.tyoskentelyjaksoTilastot}})],1)],1)],1)]),(_vm.edistyminen.yekSuoritusPvm)?_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('muut-koulutukset'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{staticClass:"text-success mr-1",attrs:{"icon":['fas', 'check-circle']}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('yek-suoritettu'))+" "+_vm._s(_vm.$date(_vm.edistyminen.yekSuoritusPvm))+" ")])],1)])]):_vm._e()]),_c('b-col',{attrs:{"xl":"4"}},[_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0 pb-1",attrs:{"to":{
                name: 'teoriakoulutukset'
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('teoriakoulutus'))+" ")])]),_c('elsa-progress-bar',{staticClass:"mb-3",attrs:{"value":_vm.edistyminen.teoriakoulutuksetSuoritettu,"min-required":_vm.edistyminen.teoriakoulutuksetVaadittu,"show-required-text":true,"color":"#41b257","background-color":"#b3e1bc","text-color":"#000","custom-unit":_vm.$t('t')}}),_c('elsa-button',{staticClass:"pl-0 border-0 pt-0 pb-1",attrs:{"to":{ name: 'opintosuoritukset', hash: '#johtamisopinnot' },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('johtamisopinnot'))+" ")])]),_c('elsa-progress-bar',{staticClass:"mb-3",attrs:{"value":_vm.edistyminen.johtamisopinnotSuoritettu,"min-required":_vm.edistyminen.johtamisopinnotVaadittu,"show-required-text":true,"color":"#41b257","background-color":"#b3e1bc","text-color":"#000","custom-unit":_vm.$t('opintopistetta-lyhenne')}}),(_vm.edistyminen.sateilysuojakoulutuksetVaadittu > 0)?_c('div',[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0 pb-1",attrs:{"to":{ name: 'opintosuoritukset', hash: '#sateilysuojakoulutukset' },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('sateilysuojelukoulutus'))+" ")])]),_c('elsa-progress-bar',{staticClass:"mb-3",attrs:{"value":_vm.edistyminen.sateilysuojakoulutuksetSuoritettu,"min-required":_vm.edistyminen.sateilysuojakoulutuksetVaadittu,"show-required-text":true,"color":"#41b257","background-color":"#b3e1bc","text-color":"#000","custom-unit":_vm.$t('opintopistetta-lyhenne')}})],1):_vm._e()],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: _vm.koejaksoComponent(_vm.edistyminen.koejaksonSuoritusmerkintaExists),
                hash: _vm.koejaksoComponentHash(_vm.edistyminen.koejaksonSuoritusmerkintaExists)
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('koejakso'))+" ")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('font-awesome-icon',{class:_vm.koejaksoIconClass(
                    _vm.edistyminen.koejaksoTila,
                    _vm.edistyminen.opintooikeudenPaattymispaiva
                  ),attrs:{"icon":_vm.koejaksoIcon(_vm.edistyminen.koejaksoTila, _vm.edistyminen.opintooikeudenPaattymispaiva)}}),_c('span',{staticClass:"ml-1",class:_vm.koejaksoStatusClass(
                    _vm.edistyminen.koejaksoTila,
                    _vm.edistyminen.opintooikeudenPaattymispaiva
                  )},[_vm._v(" "+_vm._s(_vm.koejaksoStatusText(_vm.edistyminen.koejaksoTila))+" ")])],1)],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{
                name: _vm.terveyskeskusjaksoComponent(
                  _vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu
                ),
                hash: _vm.terveyskeskusjaksoComponentHash(
                  _vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu
                )
              },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('terveyskeskuskoulutusjakso'))+" ")])]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.terveyskeskusjaksoIcon(_vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu))?_c('font-awesome-icon',{class:_vm.terveyskeskusjaksoIconClass(_vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu),attrs:{"icon":_vm.terveyskeskusjaksoIcon(_vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu)}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.terveyskeskusjaksoStatusText(_vm.edistyminen.terveyskeskuskoulutusjaksoSuoritettu))+" ")])],1)],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('elsa-button',{staticClass:"pl-0 border-0 pt-0",attrs:{"to":{ name: 'opintosuoritukset', hash: '#kuulustelu' },"variant":"link"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('kuulustelu'))+" ")])]),_c('div',[_vm._v(" "+_vm._s(_vm.edistyminen.valtakunnallisetKuulustelutSuoritettuLkm)+" "+_vm._s(_vm.$t('kpl'))+" ")])],1)]),_c('div',{staticClass:"border rounded pt-3 pb-2 mb-2"},[_c('div',{staticClass:"container-fluid"},[_c('h3',[_vm._v(_vm._s(_vm.$t('opintooikeus')))]),_c('div',{staticClass:"d-flex align-items-center"},[(_vm.showOpintooikeusAlert(_vm.edistyminen.opintooikeudenPaattymispaiva))?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'exclamation-circle']}}):_vm._e(),_c('span',{class:{
                  'text-danger ml-1': _vm.showOpintooikeusAlert(
                    _vm.edistyminen.opintooikeudenPaattymispaiva
                  )
                }},[_vm._v(" "+_vm._s(_vm.$date(_vm.edistyminen.opintooikeudenMyontamispaiva))+" - "+_vm._s(_vm.$date(_vm.edistyminen.opintooikeudenPaattymispaiva))+" ")])],1)])])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }