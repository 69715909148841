import { render, staticRenderFns } from "./erikoistumisen-edistyminen-card.vue?vue&type=template&id=660a3ecf&scoped=true&"
import script from "./erikoistumisen-edistyminen-card.vue?vue&type=script&lang=ts&"
export * from "./erikoistumisen-edistyminen-card.vue?vue&type=script&lang=ts&"
import style0 from "./erikoistumisen-edistyminen-card.vue?vue&type=style&index=0&id=660a3ecf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660a3ecf",
  null
  
)

export default component.exports